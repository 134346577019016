import { zodResolver } from "@hookform/resolvers/zod";
import { useCallback, useMemo, useRef, useState } from "react";
import { FieldErrors, FieldPath, useForm, UseFormStateReturn } from "react-hook-form";
import * as Sentry from "@sentry/react";
import { z } from "zod";

import { ApplicationStatusSchema, DateStringSchema, PaymentMode } from "@packages/types";

import {
  ApplicantApi,
  ApplicationApi,
  FeeWaiverCodeApi,
  GetApi,
  IApplicant,
  IApplicantUpdatePayload,
  IApplication,
  IApplicationDraftReversionPayload,
  IApplicationUpdatePayload,
} from "~/api";
import {
  doesObjectHaveTrueValues,
  getObjectValueByPath,
  smoothScrollTo,
  smoothScrollToTopElement,
} from "~/utils";
import { useIsDeveloper } from "~/hooks/admin";
import { useSetApplicationFormSidebarMap } from "~/components/application/ApplicationFormSidebar";
import { useNotification } from "~/components/core/NotificationProvider";
import { useLoadResource } from "~/components/core/AppLoadingBar";

import {
  createApplicationUpdatePayload,
  mapApplicationToForm,
} from "./useApplicationForm.utils";
import { PersonalDetailsForm } from "./PersonalDetailsForm";
import { MonashStudiesForm } from "./MonashStudiesForm";
import { CitizenshipDetailsForm } from "./CitizenshipDetailsForm";
import { CoursePreferencesForm } from "./CoursePreferencesForm";
import { EnglishLanguageProficiencyForm } from "./EnglishLanguageProficiencyForm";
import { DisabilitiesForm } from "./DisabilitiesForm";
import { AcademicQualificationsForm } from "./AcademicQualificationsForm";
import { WorkExperienceForm } from "./WorkExperienceForm";
import { ScholarshipSponsorshipForm } from "./ScholarshipSponsorshipForm";
import { CreditTransferForm } from "./CreditTransferForm";
import { ApplicationFeesForm } from "./ApplicationFeesForm";
import { NotesForm } from "./NotesForm";
import { DelegatedAgencyForm } from "./DelegatedAgencyForm";
import { useMaintenanceMode } from "~/hooks/maintenance-mode";

export type ApplicationFields = z.infer<typeof ApplicationSubmitSchema>;

export type ApplicationSectionKey = keyof Omit<
  ApplicationFields,
  | "applicationId"
  | "applicantId"
  | "lastModifiedDate"
  | "applicationStatus"
  | "applicationOutcome"
>;

const ApplicationSubmitSchema = z.object({
  applicationStatus: ApplicationStatusSchema,
  lastModifiedDate: DateStringSchema(),
  delegatedAgency: DelegatedAgencyForm.submitSchema,
  personalDetails: PersonalDetailsForm.submitSchema,
  monashStudies: MonashStudiesForm.submitSchema,
  citizenship: CitizenshipDetailsForm.submitSchema,
  coursePreferences: CoursePreferencesForm.submitSchema,
  englishProficiency: EnglishLanguageProficiencyForm.submitSchema,
  disabilities: DisabilitiesForm.submitSchema,
  academicQualifications: AcademicQualificationsForm.submitSchema,
  workExperience: WorkExperienceForm.submitSchema,
  scholarshipSponsorship: ScholarshipSponsorshipForm.submitSchema,
  creditTransfer: CreditTransferForm.submitSchema,
  notes: NotesForm.submitSchema,
  fees: ApplicationFeesForm.submitSchema,
  documents: z.object({}).optional(),
});

const ApplicationDraftSchema = z.object({
  applicationStatus: ApplicationStatusSchema,
  lastModifiedDate: DateStringSchema(),
  delegatedAgency: DelegatedAgencyForm.draftSchema,
  personalDetails: PersonalDetailsForm.draftSchema,
  monashStudies: MonashStudiesForm.draftSchema,
  citizenship: CitizenshipDetailsForm.draftSchema,
  coursePreferences: CoursePreferencesForm.draftSchema,
  englishProficiency: EnglishLanguageProficiencyForm.draftSchema,
  disabilities: DisabilitiesForm.draftSchema,
  academicQualifications: AcademicQualificationsForm.draftSchema,
  workExperience: WorkExperienceForm.draftSchema,
  scholarshipSponsorship: ScholarshipSponsorshipForm.draftSchema,
  creditTransfer: CreditTransferForm.draftSchema,
  notes: NotesForm.draftSchema,
  fees: ApplicationFeesForm.draftSchema,
  documents: z.object({}).optional(),
});

export type UseApplicationForm = ReturnType<typeof useApplicationForm>;

export function useApplicationForm(options: {
  applicant: IApplicant;
  application: IApplication;
  englishCourseCodes: string[];
  monashCollegeFaculties: string[];
  /** Callback to run after a save/submission */
  onAfterUpdate?: (info: { saveSuccessful: boolean }) => void;
}) {
  const {
    applicant,
    application,
    englishCourseCodes,
    monashCollegeFaculties,
    onAfterUpdate,
  } = options;

  const setSidebar = useSetApplicationFormSidebarMap();
  const formRef = useRef<HTMLFormElement>(null);
  const isDeveloper = useIsDeveloper();

  const { applicantId } = applicant;
  const { applicationId } = application.application;

  const [loading, setSaving] = useState(false);
  const { showNotification, showErrorNotification, showErrorAlert } = useNotification();

  const form = useForm<ApplicationFields>({
    resolver: zodResolver(ApplicationSubmitSchema),
    defaultValues: mapApplicationToForm(options),
  });

  const { active: maintenanceModeActive } = useMaintenanceMode().data ?? {};

  const loadForm = useCallback(
    async (data: { applicant: IApplicant; application: IApplication }) => {
      const { applicant, application } = data;

      const {
        applicationStatus,
        lastModifiedDate,
        delegatedAgency,
        personalDetails,
        monashStudies,
        citizenship,
        coursePreferences,
        englishProficiency,
        disabilities,
        academicQualifications,
        workExperience,
        scholarshipSponsorship,
        creditTransfer,
        notes,
        fees,
      } = mapApplicationToForm({
        applicant,
        application,
        englishCourseCodes,
        monashCollegeFaculties,
      });

      form.reset({
        applicationStatus,
        delegatedAgency,
        lastModifiedDate,
        personalDetails,
        monashStudies,
        citizenship,
        coursePreferences,
        englishProficiency,
        disabilities,
        academicQualifications,
        workExperience,
        scholarshipSponsorship,
        creditTransfer,
        notes,
        fees,
      });

      console.debug("Form loaded");
    },
    [form, englishCourseCodes, monashCollegeFaculties],
  );

  const onSave = useCallback(async (): Promise<{ valid: boolean; success: boolean }> => {
    const data = form.getValues();
    form.clearErrors();

    setSaving(true);

    const { feeWaiverCode, paymentMethod } = data.fees;
    if (feeWaiverCode && paymentMethod?.code === PaymentMode.ApplicationFeeWaiverCode)
      showNotification({ type: "loading", message: "Validating application..." });

    // Check for form validation errors
    const result = ApplicationDraftSchema.safeParse(data);
    if (!result.success) {
      const elementIds: string[] = [];

      // Set the error in the UI for each issue
      result.error.issues.forEach(({ message, path }) => {
        const name = path.join(".") as FieldPath<ApplicationFields>;
        form.setError(name, { type: "value", message });
        elementIds.push(name);
        if (isDeveloper) console.warn({ message, path });
      });

      smoothScrollToTopElement(elementIds);
    }

    // Only check fee waiver code if there were no validation errors AND the maintenance mode is not active
    let feeWaiverCodeOk = true;
    if (!maintenanceModeActive && result.success) {
      const feeWaiverCodeStatus = await getFeeWaiverCodeStatus(
        feeWaiverCode,
        paymentMethod?.code,
      );
      feeWaiverCodeOk =
        feeWaiverCodeStatus === "usable" || feeWaiverCodeStatus === "none";
      if (!feeWaiverCodeOk) {
        form.setError("fees", { type: "custom", message: feeWaiverCodeStatus });
        smoothScrollTo("fees");
      }
    }

    if (!result.success || !feeWaiverCodeOk) {
      showNotification({
        type: "warning",
        message:
          "There are incomplete sections in this application that need to be filled in before the draft can be saved.",
      });

      setSaving(false);

      return { valid: false, success: false };
    }

    showNotification({ type: "loading", message: "Saving draft..." });

    const { shouldUpdateApplicant, shouldUpdateApplication } = getShouldUpdate(
      form.formState,
    );
    const { applicant, application } = createApplicationUpdatePayload(
      { applicantId, applicationId },
      result.data,
    );

    try {
      const { updatedApplicant, updatedApplication } = await saveApplicantAndApplication({
        applicantId,
        applicant,
        applicationId,
        application,
        skipApplicantUpdate: !shouldUpdateApplicant,
        skipApplicationUpdate: !shouldUpdateApplication,
      });
      showNotification({ type: "success", message: "Draft saved" });
      loadForm({ applicant: updatedApplicant, application: updatedApplication });
      onAfterUpdate?.({ saveSuccessful: true });
      return { valid: true, success: true };
    } catch (error) {
      // send error to Sentry
      Sentry.captureException(error, {
        tags: { source: "useApplicationForm.onSave" },
      });

      showErrorNotification(error);
      onAfterUpdate?.({ saveSuccessful: false });
      return { valid: true, success: false };
    } finally {
      setSaving(false);
    }
  }, [
    form,
    applicantId,
    applicationId,
    isDeveloper,
    loadForm,
    onAfterUpdate,
    showErrorNotification,
  ]);

  const onInvalidSubmit = useCallback(
    async (errors: FieldErrors<ApplicationFields>) => {
      const {
        delegatedAgency,
        personalDetails,
        monashStudies,
        citizenship,
        coursePreferences,
        englishProficiency,
        disabilities,
        academicQualifications,
        workExperience,
        scholarshipSponsorship,
        creditTransfer,
        notes,
        fees,
        documents,
      } = errors;

      if (isDeveloper) console.warn(errors);

      // Extract the relevant keys out of the error object
      const applicationSections = {
        delegatedAgency,
        personalDetails,
        monashStudies,
        citizenship,
        coursePreferences,
        englishProficiency,
        disabilities,
        academicQualifications,
        workExperience,
        scholarshipSponsorship,
        creditTransfer,
        notes,
        fees,
        documents,
      };

      const sections = Object.keys(applicationSections) as ApplicationSectionKey[];

      // Scroll to top most error
      smoothScrollToTopElement(
        sections.filter((section) => applicationSections[section]),
      );

      // Set whether each section is valid and set the sidebar status
      sections.forEach((section) => {
        setSidebar(section, { touched: true, valid: !Boolean(errors[section]) });
      });

      showNotification({
        type: "error",
        message: "Please complete the application before sending it to the applicant.",
      });
    },
    [setSidebar, showNotification, isDeveloper],
  );

  const onValidSubmit = useCallback(
    async (data: ApplicationFields) => {
      setSaving(true);

      const { feeWaiverCode, paymentMethod } = data.fees;
      if (feeWaiverCode && paymentMethod?.code === PaymentMode.ApplicationFeeWaiverCode)
        showNotification({ type: "loading", message: "Validating application..." });

      const hasWorkExperienceOrAcademicQualification =
        data.academicQualifications.qualifications.length || data.workExperience.length;
      if (!hasWorkExperienceOrAcademicQualification) {
        form.setError("workExperience", {
          type: "custom",
          message: "Please add at least one academic qualification or work experience.",
        });
        form.setError("academicQualifications.qualifications", {
          type: "custom",
          message: "Please add at least one academic qualification or work experience.",
        });
        smoothScrollTo("academicQualifications");
      }

      // Only check fee waiver code if there were no validation errors AND the maintenance mode is not active
      let feeWaiverCodeOk = true;
      if (!maintenanceModeActive && hasWorkExperienceOrAcademicQualification) {
        const feeWaiverCodeStatus = await getFeeWaiverCodeStatus(
          feeWaiverCode,
          paymentMethod?.code,
        );
        feeWaiverCodeOk =
          feeWaiverCodeStatus === "usable" || feeWaiverCodeStatus === "none";
        if (!feeWaiverCodeOk) {
          form.setError("fees", { type: "custom", message: feeWaiverCodeStatus });
          smoothScrollTo("fees");
        }
      }

      if (!hasWorkExperienceOrAcademicQualification || !feeWaiverCodeOk) {
        showNotification({
          type: "warning",
          message:
            "There are incomplete sections in this application that need to be filled in before this application can be submitted.",
        });
        setSaving(false);
        return;
      }

      showNotification({ type: "loading", message: "Sending draft to applicant..." });

      const { shouldUpdateApplicant } = getShouldUpdate(form.formState);
      const { applicant, application } = createApplicationUpdatePayload(
        { applicantId, applicationId },
        data,
      );
      application.application.applicationStatus = "Sent Draft Application to Applicant";

      try {
        const { updatedApplicant, updatedApplication } =
          await saveApplicantAndApplication({
            applicantId,
            applicant,
            applicationId,
            application,
            skipApplicantUpdate: !shouldUpdateApplicant,
            // Application will always be updated here
          });
        showNotification({ type: "success", message: "Draft sent to applicant." });
        loadForm({ applicant: updatedApplicant, application: updatedApplication });
        onAfterUpdate?.({ saveSuccessful: true });
      } catch (error) {
        // send error to Sentry
        Sentry.captureException(error, {
          tags: { source: "useApplicationForm.onValidSubmit" },
        });

        showErrorAlert(error, "Unable to submit application");
        onAfterUpdate?.({ saveSuccessful: false });
      } finally {
        setSaving(false);
      }
    },
    [
      applicantId,
      applicationId,
      loadForm,
      onAfterUpdate,
      showNotification,
      showErrorAlert,
    ],
  );

  /** Set application to draft without modifying any other values. */
  const setToDraft = useCallback(async () => {
    // get rid of any modified values and get the original form data
    form.reset();
    const data = form.getValues();

    const applicantApi = GetApi(ApplicantApi);
    const applicationApi = GetApi(ApplicationApi);

    setSaving(true);
    showNotification({
      type: "loading",
      message: "Setting application to Draft status...",
    });

    const { application: applicationUpdatePayload } = createApplicationUpdatePayload(
      { applicantId, applicationId },
      data,
    );
    // remove properties we don't want to send in the payload
    const {
      qualifications: {},
      workExperiences: {},
      ...applicant
    } = applicationUpdatePayload.applicant;
    const {
      coursePreferences: {},
      ...application
    } = applicationUpdatePayload.application;
    delete application.payment;

    const applicationDraftReversionPayload: IApplicationDraftReversionPayload = {
      applicant,
      application,
    };
    applicationDraftReversionPayload.application.applicationStatus = "Draft";

    try {
      // Fetch the updated applicant while reverting the application to draft
      const [updatedApplicant] = await Promise.all([
        applicantApi.getApplicant(applicantId),
        applicationApi.revertApplicationToDraft(
          applicationId,
          applicationDraftReversionPayload,
        ),
      ]);

      // Fetch updated application
      const updatedApplication = await applicationApi.getApplication(applicationId);

      showNotification({
        type: "success",
        message: "Application reset to Draft status.",
      });
      loadForm({ applicant: updatedApplicant, application: updatedApplication });
      onAfterUpdate?.({ saveSuccessful: true });
    } catch (error) {
      // send error to Sentry
      Sentry.captureException(error, {
        tags: { source: "useApplicationForm.setToDraft" },
      });

      showErrorAlert(error, "Unable to reset application to Draft status.");
      onAfterUpdate?.({ saveSuccessful: false });
    } finally {
      setSaving(false);
    }
  }, [
    applicantId,
    applicationId,
    loadForm,
    onAfterUpdate,
    showNotification,
    showErrorAlert,
  ]);

  /**
   * Fetch the latest values from the server and reload the form.
   * @param successMessage Optional success message text to show after reloading the form.
   */
  const refreshForm = useCallback(
    async (successMessage?: string) => {
      setSaving(true);
      showNotification({
        type: "loading",
        message: "Refreshing form...",
      });

      try {
        const [updatedApplicant, updatedApplication] = await Promise.all([
          GetApi(ApplicantApi).getApplicant(applicantId),
          GetApi(ApplicationApi).getApplication(applicationId),
        ]);
        await loadForm({ applicant: updatedApplicant, application: updatedApplication });
        showNotification({
          type: "success",
          message: successMessage || "Form updated with latest values.",
        });
      } catch (error) {
        // send error to Sentry
        Sentry.captureException(error, {
          tags: { source: "useApplicationForm.refreshForm" },
        });

        showErrorNotification(error, "Unable to refresh application");
      } finally {
        setSaving(false);
      }
    },
    [applicantId, applicationId, loadForm, showNotification],
  );

  // Show loading app bar
  useLoadResource(
    useCallback(() => loading, [loading]),
    useApplicationForm.name,
  );

  return useMemo(
    () => ({
      form,
      formRef,
      loading,
      onSave,
      onInvalidSubmit,
      onValidSubmit,
      setToDraft,
      refreshForm,
    }),
    [
      form,
      formRef,
      loading,
      onSave,
      onInvalidSubmit,
      onValidSubmit,
      setToDraft,
      refreshForm,
    ],
  );
}

async function saveApplicantAndApplication(options: {
  applicationId: string;
  applicantId: string;
  applicant: IApplicantUpdatePayload;
  application: IApplicationUpdatePayload;
  skipApplicantUpdate?: boolean;
  skipApplicationUpdate?: boolean;
}) {
  const {
    applicationId,
    applicantId,
    applicant,
    application,
    skipApplicantUpdate,
    skipApplicationUpdate,
  } = options;

  const applicantApi = GetApi(ApplicantApi);
  const applicationApi = GetApi(ApplicationApi);

  // Update applicant first
  if (!skipApplicantUpdate) await applicantApi.updateApplicant(applicantId, applicant);

  // If we don't need to update the application, then just fetch both and return
  if (skipApplicationUpdate) {
    const [updatedApplicant, updatedApplication] = await Promise.all([
      applicantApi.getApplicant(applicantId),
      applicationApi.getApplication(applicationId),
    ]);
    return { updatedApplicant, updatedApplication };
  }

  // Fetch the updated applicant while updating application
  const [updatedApplicant] = await Promise.all([
    applicantApi.getApplicant(applicantId),
    applicationApi.updateApplication(applicationId, application),
  ]);

  // Fetch updated application
  const updatedApplication = await applicationApi.getApplication(applicationId);

  return { updatedApplicant, updatedApplication };
}

async function getFeeWaiverCodeStatus(
  code?: string | null,
  paymentMethod?: string | null,
) {
  if (!code || paymentMethod !== PaymentMode.ApplicationFeeWaiverCode) return "none";
  const feeWaiverVerification = await GetApi(FeeWaiverCodeApi).getFeeWaiverCode(code);
  return feeWaiverVerification.status;
}

const APPLICANT_FIELDS = [
  "personalDetails",
  "monashStudies",
] satisfies FieldPath<ApplicationFields>[];

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const APPLICATION_FIELDS = [
  "citizenship",
  "coursePreferences",
  "englishProficiency",
  "disabilities",
  "academicQualifications",
  "workExperience",
  "scholarshipSponsorship",
  "creditTransfer",
  "notes",
  "fees",
] satisfies FieldPath<ApplicationFields>[];

function getShouldUpdate(formState: UseFormStateReturn<ApplicationFields>) {
  const shouldUpdateApplicant = APPLICANT_FIELDS.map((path) => {
    const dirtyField = getObjectValueByPath(formState.dirtyFields, path);
    return doesObjectHaveTrueValues(dirtyField || {});
  }).some((isDirty) => isDirty);

  // FIXME: unable to detect whether array fields are dirty properly due to a (potential?)
  // bug in react-hook-form. See https://github.com/react-hook-form/react-hook-form/issues/3508

  // const shouldUpdateApplication = APPLICATION_FIELDS.map((path) => {
  //   const dirtyField = getObjectValueByPath(formState.dirtyFields, path);
  //   return doesObjectHaveTrueValues(dirtyField || {});
  // }).some((isDirty) => isDirty);

  // console.log({
  //   isDirty: formState.isDirty,
  //   dirtyFields: formState.dirtyFields,
  //   shouldUpdateApplicant,
  //   // shouldUpdateApplication,
  // });

  // For now, we'll always update the application.
  return { shouldUpdateApplicant, shouldUpdateApplication: true };
}
