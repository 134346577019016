import { Control, UseFormSetValue, useWatch } from "react-hook-form";
import { Button, Grid, MenuItem, createFilterOptions } from "@mui/material";
import { FormSection } from "~/components/form/FormSection";
import { FormSelect } from "~/components/form/FormSelect";
import { FormTextField } from "~/components/form/FormTextField";
import { FormYesNo } from "~/components/form/FormYesNo";
import { FormCountrySACCSelect } from "~/components/form/FormCountrySelect";
import { FormAutocomplete } from "~/components/form/FormAutocomplete";

import {
  useInstitutions,
  useReferenceData,
  useSecondaryQualificationTypes,
} from "~/hooks/reference-data";
import { GetFieldPathToType } from "~/types";

import {
  AssessmentType,
  SelectedInstitution,
  AcademicQualification,
} from "~/components/application/ApplicationForm/AcademicQualificationsForm";
import { ApplicationFields } from "../ApplicationForm";

export interface AcademicQualificationFieldsProps {
  control: Control<ApplicationFields>;
  setValue: UseFormSetValue<ApplicationFields>;
  path: GetFieldPathToType<
    AcademicQualification | EmptyQualificationType,
    ApplicationFields
  >;
  onConfirm: () => void;
  onCancel: () => void;
  title?: React.ReactNode;
  disabled?: boolean;
}

const filter = createFilterOptions<SelectedInstitution>();

export type EmptyQualificationType = { type: "" };
export const EmptyQualification: EmptyQualificationType = { type: "" };

export const EmptyTertiaryEducation: AcademicQualification = {
  type: "Tertiary Education",
  name: "",
  country: null,
  institution: null,
  firstYearEnrolled: "",
  lastYearEnrolled: "",
  levelOfCompletion: "",
};

export const EmptySecondaryEducation: AcademicQualification = {
  type: "Secondary Education",
  country: null,
  state: "",
  assessmentType: null,
  institutionName: "",
  completed: true,
  lastYearEnrolled: "",
  expectedDateOfCompletion: "",
};

export const EmptyOtherQualification: AcademicQualification = {
  type: "Other Qualification",
  name: "",
  completed: true,
  dateAchieved: "",
  expectedDateOfCompletion: "",
};

export function AcademicQualificationFields(props: AcademicQualificationFieldsProps) {
  const {
    title = "Qualification",
    control,
    path,
    setValue,
    onConfirm,
    onCancel,
    disabled,
  } = props;

  const { type } = useWatch({ control, name: path }) as AcademicQualification;
  const [country, state, assessmentType, completed] = useWatch({
    control,
    name: [
      `${path}.country`,
      `${path}.state`,
      `${path}.assessmentType`,
      `${path}.completed`,
    ],
  });

  const isOverseas = country?.sacCountryCode !== "1101"; // 1101 is the SACC code for Australia

  const qualificationTypes = useReferenceData("QualificationTypes");
  const levelOfCompletion = useReferenceData("QualificationStatuses");
  const assessmentTypes = useSecondaryQualificationTypes({ isOverseas });
  const institutions = useInstitutions({
    type:
      type === "Tertiary Education"
        ? "Tertiary"
        : type === "Secondary Education"
          ? "Secondary"
          : undefined,
    country: country?.isoCountryName,
  });

  const states = Array.from(
    new Set(
      assessmentTypes.data
        ?.map((x) => x.state)
        .filter((x) => x)
        .sort((a, b) => a.localeCompare(b)),
    ),
  );

  return (
    <>
      <Grid item xs={12} mt={1}>
        <FormSection.Subheading gutterBottom={false}>{title}</FormSection.Subheading>
      </Grid>
      <Grid item xs={12}>
        <FormSelect
          control={control}
          name={`${path}.type`}
          label="Qualification type"
          disabled={disabled}
          loading={qualificationTypes.isPending}
          onBeforeChange={(e) => {
            const selectedType = e.target.value as typeof type;
            switch (selectedType) {
              case "Tertiary Education":
                setValue(path, EmptyTertiaryEducation);
                break;
              case "Secondary Education":
                setValue(path, EmptySecondaryEducation);
                break;
              case "Other Qualification":
                setValue(path, EmptyOtherQualification);
                break;
            }
          }}
        >
          {qualificationTypes.data?.map(({ label, value }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </FormSelect>
      </Grid>

      {type === "Tertiary Education" && (
        <>
          <Grid item xs={12} md={6}>
            <FormCountrySACCSelect
              control={control}
              name={`${path}.country`}
              label="Country"
              disabled={disabled}
              fullWidth
              onBeforeChange={(selectedCountry) => {
                if (selectedCountry?.id !== country?.id)
                  setValue(`${path}.institution`, null);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormAutocomplete
              control={control}
              name={`${path}.institution`}
              label="Institution"
              options={(institutions.data as SelectedInstitution[]) ?? []}
              filterOptions={(options, params) => {
                const { inputValue } = params;
                const nonNullOptions = options.filter(
                  (x): x is NonNullable<typeof x> => x !== null,
                );
                const filtered = filter(nonNullOptions, params);
                const isExisting = nonNullOptions.some(
                  (x) => inputValue === x.institutionName,
                );
                if (inputValue !== "" && !isExisting)
                  filtered.push({
                    label: `Add "${inputValue}" as a custom institution`,
                    institutionName: inputValue,
                  });
                return filtered;
              }}
              parseInputValue={(value) =>
                !value || typeof value === "string"
                  ? { id: "", institutionName: value ?? "" }
                  : value
              }
              getOptionLabel={(option) => {
                // If option is a string, the value was typed and enter was pressed
                if (typeof option === "string") return option;
                return option?.institutionName ?? "";
              }}
              renderOption={(props, option) => (
                <li {...props}>{option?.label ?? option?.institutionName ?? ""}</li>
              )}
              loading={institutions.isPending}
              disabled={disabled || !country}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              placeholder={
                institutions.data?.length === 0
                  ? "Please type an institution name and press enter"
                  : "Select an institution or type an institution name and press enter"
              }
              helperText={
                !disabled &&
                !country &&
                "Please select a country before entering an institution"
              }
              handleHomeEndKeys
              selectOnFocus
              clearOnBlur
              freeSolo
            />
          </Grid>
          <Grid item xs={12}>
            <FormTextField
              control={control}
              name={`${path}.name`}
              label="Name of Qualification"
              disabled={disabled}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <FormTextField
              control={control}
              name={`${path}.firstYearEnrolled`}
              label="First year of enrolment"
              disabled={disabled}
              type="number"
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <FormTextField
              control={control}
              name={`${path}.lastYearEnrolled`}
              label="Last year of enrolment"
              disabled={disabled}
              type="number"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormSelect
              control={control}
              name={`${path}.levelOfCompletion`}
              label="Level of completion"
              disabled={disabled}
              loading={levelOfCompletion.isPending}
              fullWidth
            >
              {levelOfCompletion.data?.map(({ label, value }) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </FormSelect>
          </Grid>
        </>
      )}

      {type === "Secondary Education" && (
        <>
          <Grid item xs={12} md={6}>
            <FormCountrySACCSelect
              control={control}
              name={`${path}.country`}
              label="Country"
              disabled={disabled}
              fullWidth
              onBeforeChange={() => {
                setValue(`${path}.state`, "");
                setValue(`${path}.assessmentType`, null);
              }}
            />
          </Grid>
          {!isOverseas && (
            <Grid item xs={12} md={6}>
              <FormSelect
                control={control}
                name={`${path}.state`}
                label="State"
                loading={assessmentTypes.isFetching}
                disabled={disabled}
                fullWidth
                onBeforeChange={(e) => {
                  if (assessmentType?.state && e.target.value !== assessmentType?.state)
                    setValue(`${path}.assessmentType`, null);
                }}
              >
                {states.map((state) => (
                  <MenuItem key={state} value={state}>
                    {state}
                  </MenuItem>
                ))}
              </FormSelect>
            </Grid>
          )}
          <Grid item xs={12}>
            <FormAutocomplete
              control={control}
              name={`${path}.assessmentType`}
              label="Assessment type"
              options={((assessmentTypes.data ?? []) as AssessmentType[]).filter(
                (x) => isOverseas || !x.state || x.state === state,
              )}
              getOptionLabel={(x) => x?.description ?? ""}
              loading={assessmentTypes.isFetching}
              loadingText="Loading..."
              noOptionsText="No matches"
              defaultValue={null}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              disabled={disabled}
              autoHighlight
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <FormTextField
              control={control}
              name={`${path}.institutionName`}
              label="Institution name"
              disabled={disabled}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <FormYesNo
              control={control}
              name={`${path}.completed`}
              label="Has the applicant completed this qualification?"
              disabled={disabled}
              fullWidth
            />
          </Grid>
          {completed ? (
            <Grid item xs={6} md={4}>
              <FormTextField
                key="lastYearEnrolled"
                control={control}
                name={`${path}.lastYearEnrolled`}
                label="Year of completion"
                disabled={disabled}
                type="number"
                fullWidth
              />
            </Grid>
          ) : (
            <Grid item xs={6}>
              <FormTextField
                key="expectedDateOfCompletion"
                control={control}
                name={`${path}.expectedDateOfCompletion`}
                label="Expected date of completion"
                disabled={disabled}
                type="date"
                fullWidth
              />
            </Grid>
          )}
        </>
      )}

      {type === "Other Qualification" && (
        <>
          <Grid item xs={12}>
            <FormTextField
              control={control}
              name={`${path}.name`}
              label="Name of Qualification"
              disabled={disabled}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <FormYesNo
              control={control}
              name={`${path}.completed`}
              label="Has the applicant completed this qualification?"
              disabled={disabled}
              fullWidth
            />
          </Grid>
          {completed ? (
            <Grid item xs={6}>
              <FormTextField
                key="dateAchieved"
                control={control}
                name={`${path}.dateAchieved`}
                label="Date achieved"
                disabled={disabled}
                type="date"
                fullWidth
              />
            </Grid>
          ) : (
            <Grid item xs={6}>
              <FormTextField
                key="expectedDateOfCompletion"
                control={control}
                name={`${path}.expectedDateOfCompletion`}
                label="Expected date of completion"
                disabled={disabled}
                type="date"
                fullWidth
              />
            </Grid>
          )}
        </>
      )}

      <Grid item xs={12} container gap={2}>
        <Button variant="contained" onClick={onConfirm}>
          Confirm
        </Button>
        <Button variant="contained" color="error" onClick={onCancel}>
          Cancel
        </Button>
      </Grid>
    </>
  );
}
