/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  IErrorResponse,
  ITestingCredentialRedactedDTO,
  ITestingCredentialRedactedPageDTO,
  ITestingCredentialSecretsDTO,
  ITestingCredentialUpdateDTO,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class TestingCredentialsApi<
  SecurityDataType = unknown,
> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Testing Credentials API
   * @name ListTestingCredentials
   * @summary List all available testing credentials
   * @request GET:/api/testing-credentials
   * @response `200` `ITestingCredentialRedactedPageDTO`
   * @response `401` `IErrorResponse` Not signed in.
   */
  listTestingCredentials = (
    query?: {
      /** Specify a page token for fetching the next page. */
      pageToken?: string;
      /**
       * The size of the page returned. Defaults to 10. Min of 1, max of 100.
       * @min 1
       * @max 100
       */
      pageSize?: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<ITestingCredentialRedactedPageDTO, IErrorResponse>({
      path: `/api/testing-credentials`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Testing Credentials API
   * @name GenerateTestingCredential
   * @summary Generate a new testing credential
   * @request POST:/api/testing-credentials
   * @response `201` `ITestingCredentialSecretsDTO` Testing credential created
   * @response `401` `IErrorResponse` Not signed in.
   */
  generateTestingCredential = (
    data: ITestingCredentialUpdateDTO,
    params: RequestParams = {},
  ) =>
    this.request<ITestingCredentialSecretsDTO, IErrorResponse>({
      path: `/api/testing-credentials`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Testing Credentials API
   * @name GetTestingCredential
   * @summary Get a testing credential by ID
   * @request GET:/api/testing-credentials/{testingCredentialId}
   * @response `200` `ITestingCredentialRedactedDTO`
   * @response `401` `IErrorResponse` Not signed in.
   * @response `404` `void` Testing credential not found
   */
  getTestingCredential = (testingCredentialId: string, params: RequestParams = {}) =>
    this.request<ITestingCredentialRedactedDTO, IErrorResponse | void>({
      path: `/api/testing-credentials/${testingCredentialId}`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Testing Credentials API
   * @name UpdateTestingCredential
   * @summary Update an existing testing credential
   * @request PATCH:/api/testing-credentials/{testingCredentialId}
   * @response `200` `ITestingCredentialRedactedDTO` Testing credential updated
   * @response `401` `IErrorResponse` Not signed in.
   */
  updateTestingCredential = (
    testingCredentialId: string,
    data: ITestingCredentialUpdateDTO,
    params: RequestParams = {},
  ) =>
    this.request<ITestingCredentialRedactedDTO, IErrorResponse>({
      path: `/api/testing-credentials/${testingCredentialId}`,
      method: "PATCH",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Testing Credentials API
   * @name DeleteTestingCredential
   * @summary Delete a testing credential
   * @request DELETE:/api/testing-credentials/{testingCredentialId}
   * @response `200` `void` Testing credential deleted
   * @response `401` `IErrorResponse` Not signed in.
   */
  deleteTestingCredential = (testingCredentialId: string, params: RequestParams = {}) =>
    this.request<void, IErrorResponse>({
      path: `/api/testing-credentials/${testingCredentialId}`,
      method: "DELETE",
      ...params,
    });
}
