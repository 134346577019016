import { z } from "zod";
import { Alert, Button, Grid, Link, Typography } from "@mui/material";
import {
  Control,
  UseFormSetValue,
  useFormState,
  UseFormTrigger,
  useWatch,
} from "react-hook-form";

import {
  CountrySACCSchema,
  DateStringSchema,
  parseDateString,
  parseYearString,
  SafeString,
  SafeStringForProse,
  YearStringSchema,
} from "@packages/types";

import { createAddIssue } from "~/utils";
import { useTableStateHelpers } from "~/hooks/table-helpers";
import { FormSection } from "~/components/form/FormSection";
import { FormYesNo } from "~/components/form/FormYesNo";
import {
  AcademicQualificationFields,
  AcademicQualificationsTable,
  EmptyQualification,
} from "~/components/application/AcademicQualification";

import { ApplicationFields } from "./useApplicationForm";

const LEVEL_OF_COMPLETION_CURRENTLY_STUDYING = "CURRENTLY STUDYING";

export interface AcademicQualificationsFormProps {
  control: Control<ApplicationFields>;
  setValue: UseFormSetValue<ApplicationFields>;
  trigger: UseFormTrigger<ApplicationFields>;
  disabled?: boolean;
}

export function AcademicQualificationsForm(props: AcademicQualificationsFormProps) {
  const { control, disabled, setValue } = props;

  const [isPrincipalCourseTransfer] = useWatch({
    control,
    name: ["academicQualifications.isPrincipalCourseTransfer"],
  });

  const {
    addNew,
    edit,
    confirm,
    cancel,
    fieldArray: { fields, remove },
    record,
  } = useTableStateHelpers(
    "academicQualifications.qualifications",
    props,
    EmptyQualification,
  );

  // get errors from form state and display empty alert
  const { errors } = useFormState({
    control,
    name: "academicQualifications.qualifications",
  });
  const showEmptyAlert = errors.academicQualifications?.qualifications?.type === "custom";

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormSection.Well>
            <Typography variant="body1" paragraph>
              Check the academic/English entry requirements, prerequisites and additional
              requirements for the courses for which you are applying. Additional
              requirements include submission of a statement of purpose, curriculum vitae,
              work reference letters, folio, supplementary forms, additional faculty
              application forms, interviews and auditions.
            </Typography>
            <Typography variant="body1" paragraph>
              For undergraduate entry requirements refer to the admissions tables in the
              Monash University International Undergraduate Course Guide or{" "}
              <Link
                href="https://www.monash.edu.my/study/undergraduate"
                target="_blank"
                rel="noopener"
              >
                Monash Find a Course
              </Link>
              . For postgraduate entry requirements refer to the{" "}
              <Link
                href="https://www.monash.edu.my/study/postgraduate-and-research"
                target="_blank"
                rel="noopener"
              >
                Monash Find a Postgraduate/Research Course
              </Link>{" "}
              page.
            </Typography>
            <Typography variant="body1" paragraph>
              Include details of the applicant's previous academic qualifications in the
              relevant sections below. Please ensure commencement and completion dates are
              specified, or, if the applicant is yet to complete their studies, please
              include the intended completion date. Supporting translated and certified
              documents should also be supplied to verify the academic qualifications.
            </Typography>
            <Typography variant="body1">
              If the applicant's final year of study was completed more than six months
              ago, please attach a detailed account of activities/work experience
              undertaken since then separately.
            </Typography>
          </FormSection.Well>
        </Grid>
        <Grid item xs={12}>
          <FormYesNo
            control={control}
            name="academicQualifications.isPrincipalCourseTransfer"
            label="Is the applicant transferring within the first six months of their principal course in Australia?"
            disabled={disabled}
          />
        </Grid>
        {isPrincipalCourseTransfer && (
          <Grid item xs={12}>
            <Alert severity="warning">
              If the applicant's final year of study was completed more than six months
              ago, please attach a detailed account of activities/work experience
              undertaken since then separately.
            </Alert>
          </Grid>
        )}
        <Grid item xs={12}>
          <Typography variant="body2" color="text.secondary">
            The applicant's principal course is the main course of study to be undertaken
            where they have been issued a student visa for multiple courses of study. The
            principal course would normally be the final course of study.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormSection.Subheading color="primary.main">
            Tertiary/Post-secondary/Secondary Studies
          </FormSection.Subheading>
          <Typography variant="body1">
            Please list the <strong>most recent</strong> qualification first.
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Tertiary studies – (Diploma, bachelor's, master's, etc.) or Secondary studies
            – (Year 12)
          </Typography>
        </Grid>
        {showEmptyAlert && (
          <Grid item xs={12}>
            <Alert severity="error">
              <Typography variant="body2">
                {errors.academicQualifications?.qualifications?.message}
              </Typography>
            </Alert>
          </Grid>
        )}
        <AcademicQualificationsTable
          control={control}
          fields={fields}
          remove={remove}
          onEdit={edit}
          isEditing={record.isEditing}
          editIndex={record.index}
          oldData={record.previous}
          disabled={disabled}
        />
        {!disabled && record.isEditing && (
          <AcademicQualificationFields
            title={`Qualification ${record.index + 1}`}
            control={control}
            setValue={setValue}
            path={`academicQualifications.qualifications.${record.index}`}
            onConfirm={confirm}
            onCancel={cancel}
            disabled={disabled}
          />
        )}
        {!disabled && !record.isEditing && (
          <Grid item xs={12}>
            <Button variant="contained" onClick={addNew}>
              Add qualification
            </Button>
          </Grid>
        )}
      </Grid>
    </>
  );
}

export type AcademicQualification = z.infer<typeof AcademicQualificationSchema>;

export type AssessmentType = z.infer<typeof AssessmentTypeSchema>;
const AssessmentTypeSchema = z.object({
  id: z.string(),
  description: SafeStringForProse.schema().optional(),
  state: z.string().optional(),
});

export type SelectedInstitution = z.infer<typeof SelectedInstitutionSchema>;
const SelectedInstitutionSchema = z.object({
  id: z.string().optional(),
  label: z.string().optional(),
  institutionName: SafeStringForProse.schema(),
});

export const AcademicQualificationSchema = z
  .discriminatedUnion("type", [
    z.object({
      recordId: z.string().optional(),
      type: z.literal("Tertiary Education"),
      name: SafeStringForProse.schema(),
      country: CountrySACCSchema.nullable(),
      institution: SelectedInstitutionSchema.nullable(),
      firstYearEnrolled: YearStringSchema(),
      lastYearEnrolled: YearStringSchema(),
      levelOfCompletion: z.string(),
    }),
    z.object({
      recordId: z.string().optional(),
      type: z.literal("Secondary Education"),
      assessmentType: AssessmentTypeSchema.nullable(),
      country: CountrySACCSchema.nullable(),
      state: z.string().trim(),
      institutionName: SafeString.schema(),
      completed: z.boolean(),
      lastYearEnrolled: YearStringSchema.allowBlank(),
      expectedDateOfCompletion: DateStringSchema.allowBlank(),
    }),
    z.object({
      recordId: z.string().optional(),
      type: z.literal("Other Qualification"),
      name: SafeStringForProse.schema(),
      completed: z.boolean(),
      dateAchieved: DateStringSchema.allowBlank(),
      expectedDateOfCompletion: DateStringSchema.allowBlank(),
    }),
  ])
  .superRefine((qualification, ctx) => {
    const addQualificationIssue = createAddIssue(ctx);

    const today = new Date();
    const thisYear = today.getFullYear();

    if (qualification.type === "Tertiary Education") {
      const {
        country,
        institution,
        name,
        levelOfCompletion,
        firstYearEnrolled,
        lastYearEnrolled,
      } = qualification;

      if (!country) addQualificationIssue("Please select a country", "country");

      if (!institution?.institutionName)
        addQualificationIssue("Please specify an institution", "institution");
      else if (!SafeString.isValid(institution.institutionName)) {
        addQualificationIssue(
          "Please do not use symbols or special characters",
          "institution",
        );
      }

      if (!name) addQualificationIssue("Please provide a qualification name", "name");

      if (!levelOfCompletion)
        addQualificationIssue("Please select a level of completion", "levelOfCompletion");

      const firstYearEnrolledParsed = parseYearString(firstYearEnrolled);
      const lastYearEnrolledParsed = parseYearString(lastYearEnrolled);

      if (firstYearEnrolledParsed > thisYear)
        addQualificationIssue(
          "First year of enrolment cannot be in the future",
          "firstYearEnrolled",
        );

      if (
        // Fixes DANO-70: allow qualifications that are currently studying to have a future last year of enrolment
        levelOfCompletion !== LEVEL_OF_COMPLETION_CURRENTLY_STUDYING &&
        lastYearEnrolledParsed > thisYear
      )
        addQualificationIssue(
          "Last year of enrolment cannot be in the future",
          "lastYearEnrolled",
        );

      if (firstYearEnrolledParsed > lastYearEnrolledParsed)
        addQualificationIssue(
          "Last year of enrolment must be after first year of enrolment",
          "lastYearEnrolled",
        );
    }

    if (qualification.type === "Secondary Education") {
      const {
        country,
        assessmentType,
        institutionName,
        completed,
        lastYearEnrolled,
        expectedDateOfCompletion,
      } = qualification;

      if (!country) addQualificationIssue("Please select a country", "country");

      if (!assessmentType)
        addQualificationIssue("Please select an assessment type", "assessmentType");

      if (!institutionName)
        addQualificationIssue("Please specify an institution", "institutionName");

      if (completed) {
        if (!lastYearEnrolled)
          addQualificationIssue(
            "Please specify the year of completion of this qualification",
            "lastYearEnrolled",
          );
        else if (parseYearString(lastYearEnrolled) > thisYear)
          addQualificationIssue(
            "Year of completion cannot be in the future",
            "lastYearEnrolled",
          );
      } else {
        if (!expectedDateOfCompletion)
          addQualificationIssue(
            "Please specify the expected date of completion",
            "expectedDateOfCompletion",
          );
        else if (parseDateString(expectedDateOfCompletion).getTime() < today.getTime())
          addQualificationIssue(
            "Expected date of completion cannot be in the past",
            "expectedDateOfCompletion",
          );
      }
    }

    if (qualification.type === "Other Qualification") {
      const { name, completed, dateAchieved, expectedDateOfCompletion } = qualification;

      if (!name) addQualificationIssue("Please provide a qualification name", "name");

      if (completed) {
        if (!dateAchieved)
          addQualificationIssue("Please specify the date achieved", "dateAchieved");
        else if (parseDateString(dateAchieved).getTime() > today.getTime())
          addQualificationIssue("Date achieved cannot be in the future", "dateAchieved");
      } else {
        if (!expectedDateOfCompletion)
          addQualificationIssue(
            "Please specify the expected date of completion",
            "expectedDateOfCompletion",
          );
        else if (parseDateString(expectedDateOfCompletion).getTime() < today.getTime())
          addQualificationIssue(
            "Expected date of completion cannot be in the past",
            "expectedDateOfCompletion",
          );
      }
    }
  });

AcademicQualificationsForm.draftSchema = z
  .object({
    isPrincipalCourseTransfer: z.boolean().nullable(),
    qualifications: z.array(
      AcademicQualificationSchema.or(z.object({ type: z.literal("") })),
    ),
  })
  .superRefine(({ qualifications }, ctx) => {
    qualifications.forEach((qualification, index) => {
      const addQualificationIssue = createAddIssue(ctx, "qualifications", `${index}`);

      if (qualification.type === "")
        addQualificationIssue("Please select a qualification type", "type");
    });
  });

AcademicQualificationsForm.submitSchema =
  AcademicQualificationsForm.draftSchema.superRefine(
    ({ isPrincipalCourseTransfer }, ctx) => {
      const addIssue = createAddIssue(ctx);

      if (typeof isPrincipalCourseTransfer !== "boolean")
        addIssue("Please select Yes or No", "isPrincipalCourseTransfer");
    },
  );
